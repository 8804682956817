@import url(~react-datepicker/dist/react-datepicker.css);
@import url(~rc-time-picker/assets/index.css);
main {
  padding: 0; }

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: 'open-sans', sans-serif;
  font-size: 14px; }

* {
  box-sizing: inherit; }

a {
  color: #70bbfd;
  transition: all 0.3s; }
  a:hover {
    text-decoration: none;
    color: #3ea3fc; }

img {
  width: 100%; }

ul.dash-list {
  padding-left: 1rem;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 400;
  font-size: 1.125rem;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif;
  color: #646777 !important;
  line-height: 1.5rem; }

p {
  margin: 10px 0 0 0; }
  p:first-child {
    margin: 0; }

h1 {
  font-size: 36px;
  line-height: 48px; }
  h1.subhead {
    font-size: 30px;
    color: #999999;
    line-height: 36px; }

h2 {
  font-size: 30px;
  line-height: 36px; }
  h2.subhead {
    font-size: 24px;
    color: #999999;
    line-height: 32px; }

h3 {
  font-size: 24px;
  line-height: 32px; }
  h3.subhead {
    font-size: 18px;
    color: #999999;
    line-height: 24px; }

h4 {
  font-size: 18px;
  line-height: 24px;
  color: #3E4A1B !important; }
  h4.subhead {
    font-size: 12px;
    color: #999999;
    line-height: 16px; }

.title-card {
  font-family: "Raleway", sans-serif !important;
  font-size: 24px; }

.subtitle {
  font-family: "Poppins Bold";
  font-size: 18px; }

h5 {
  font-size: 14px;
  line-height: 18px; }
  h5.subhead {
    font-size: 10px;
    color: #999999;
    line-height: 12px; }

h6 {
  font-size: 12px;
  line-height: 16px; }
  h6.subhead {
    font-size: 8px;
    color: #999999;
    line-height: 10px; }

.ltr-support .bold-text {
  text-align: left; }

.rtl-support .bold-text {
  text-align: right; }

.typography--inline {
  display: flex;
  flex-wrap: wrap; }
  .typography--inline * {
    margin-top: auto; }
    .ltr-support .typography--inline * {
      margin-right: 15px; }
    .rtl-support .typography--inline * {
      margin-left: 15px; }

.ltr-support .typography-card {
  text-align: left; }

.rtl-support .typography-card {
  text-align: right; }

.typography-card h1,
.typography-card h2,
.typography-card h3,
.typography-card h4,
.typography-card h5,
.typography-card h6 {
  margin-bottom: 10px; }

blockquote {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0; }
  .ltr-support blockquote {
    border-left: 3px solid #4ce1b6; }
  .rtl-support blockquote {
    border-right: 3px solid #4ce1b6; }
  .ltr-support blockquote {
    text-align: left;
    padding-left: 10px; }
  .rtl-support blockquote {
    text-align: right;
    padding-right: 10px; }

.highlight {
  background-color: #4ce1b6; }

.ltr-support .typography-message {
  text-align: left; }

.rtl-support .typography-message {
  text-align: right; }

.red-text {
  color: #ff4861; }

.page-title {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px; }
  .ltr-support .page-title {
    text-align: left; }
  .rtl-support .page-title {
    text-align: right; }
  .page-title:last-child {
    margin-bottom: 30px; }
  .page-title.page-title--not-last {
    margin-top: 20px; }

.page-subhead {
  margin-bottom: 20px; }
  .ltr-support .page-subhead {
    text-align: left; }
  .rtl-support .page-subhead {
    text-align: right; }
  .page-subhead.subhead {
    font-size: 14px;
    opacity: 0.7; }

::selection {
  color: #ffffff;
  background: #4ce1b6; }

.ui.menu .ui.dropdown .menu > .active.item {
  background: rgba(0, 0, 0, 0.03) !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.95) !important; }

.visible-from-right {
  visibility: visible;
  -webkit-animation: fadeIn 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
  animation: fadeIn 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1); }

.visible-from-left {
  visibility: visible;
  -webkit-animation: fadeIn 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
  animation: fadeIn 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1); }

.scroll-from-left {
  visibility: visible;
  -webkit-animation: caption-left 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
  animation: caption-left 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1); }

.scroll-from-right {
  visibility: visible;
  -webkit-animation: caption-right 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
  animation: caption-right 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1); }

.scroll-from-buttom {
  visibility: visible;
  -webkit-animation: caption-bottom 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
  animation: caption-bottom 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1); }

.scroll-from-top {
  visibility: visible;
  -webkit-animation: caption-top 0.5s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
  animation: caption-top 0.5s 1 cubic-bezier(0.985, 0.005, 0.265, 1); }

@-webkit-keyframes caption-right {
  0% {
    -webkit-transform: translate(2000px, 0);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1; } }

@keyframes caption-right {
  0% {
    transform: translate(2000px, 0);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

@-webkit-keyframes caption-left {
  0% {
    -webkit-transform: translate(-2000px, 0);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1; } }

@keyframes caption-left {
  0% {
    transform: translate(-2000px, 0);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

@-webkit-keyframes caption-top {
  0% {
    -webkit-transform: translate(0, -20px);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1; } }

@keyframes caption-top {
  0% {
    transform: translate(0, -20px);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

@-webkit-keyframes caption-bottom {
  0% {
    -webkit-transform: translate(0, 20px);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1; } }

@keyframes caption-bottom {
  0% {
    transform: translate(0, 20px);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

.element {
  margin: auto;
  height: 20px;
  width: 20px;
  background-color: red;
  animation: pulse 3s ease infinite alternate, nudge 5s linear infinite alternate;
  border-radius: 100%; }

.element2 {
  height: 20px;
  width: 20px;
  background-color: orange;
  animation: pulse 3s ease infinite alternate, nudge 4s linear infinite alternate;
  border-radius: 100%; }

@keyframes pulse {
  0%,
  100% {
    background-color: red; }
  50% {
    background-color: orange; } }

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(100%, 0); }
  80% {
    transform: translate(0%, 0); } }

.dot {
  height: 20px;
  width: 20px;
  background-image: linear-gradient(90deg, #182a73 20%, #182a73 0%, #20a7ac 89%) !important;
  border-radius: 100%;
  position: absolute;
  animation: upDown 3s alternate infinite ease-in-out; }

.sine {
  position: absolute;
  left: 0;
  width: 10%;
  animation: translate 15s infinite linear; }

@keyframes upDown {
  to {
    transform: translatey(100px); } }

@keyframes translate {
  to {
    transform: translatex(900%); } }

.fadein {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadein ease 2s;
  /* Firefox */
  -o-animation: fadein ease 2s;
  /* Opera */ }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.login-modal-form .modal-content {
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px; }

.modal-dialog {
  max-width: 385px; }

.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 50px 40px 60px 40px;
  text-align: center; }
  .theme-light .modal-content {
    background-color: white;
    color: #646777; }
  .theme-dark .modal-content {
    background-color: #232329;
    color: #dddddd; }

.modal__close-btn {
  position: absolute;
  top: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent; }
  .ltr-support .modal__close-btn {
    right: 15px; }
  .rtl-support .modal__close-btn {
    left: 15px; }
  .theme-light .modal__close-btn {
    color: #dddddd; }
  .theme-dark .modal__close-btn {
    color: #605f7b; }

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px; }

.modal__title {
  margin-top: 10px;
  margin-bottom: 15px; }
  .modal__title:first-child {
    margin-top: 0; }

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0; }
  .modal__footer button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0; }
  .ltr-support .modal__footer {
    flex-flow: row nowrap !important; }
  .rtl-support .modal__footer {
    flex-flow: row-reverse nowrap !important; }

.modal-dialog--primary .modal__title-icon {
  color: #70bbfd; }

.modal-dialog--success .modal__title-icon {
  color: #4ce1b6; }

.modal-dialog--warning .modal__title-icon {
  color: #f6da6e; }

.modal-dialog--danger .modal__title-icon {
  color: #ff4861; }

.modal-dialog--colored {
  color: #ffffff; }
  .modal-dialog--colored .modal__title-icon {
    color: #ffffff; }
  .modal-dialog--colored .modal__close-btn {
    color: #ffffff; }
  .modal-dialog--colored .modal__footer button:first-child {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.3);
    border-color: #ffffff; }
    .modal-dialog--colored .modal__footer button:first-child:before {
      background-color: rgba(255, 255, 255, 0.2); }
  .modal-dialog--colored .modal__footer button:last-child {
    border-color: white;
    color: #ffffff; }
  .modal-dialog--colored.modal-dialog--primary .modal-content {
    background-color: #70bbfd; }
  .modal-dialog--colored.modal-dialog--success .modal-content {
    background-color: #4ce1b6; }
  .modal-dialog--colored.modal-dialog--warning .modal-content {
    background-color: #f6da6e; }
  .modal-dialog--colored.modal-dialog--danger .modal-content {
    background-color: #ff4861; }

.modal-dialog--header {
  max-width: 520px; }
  .modal-dialog--header .modal-content {
    padding: 0; }
    .ltr-support .modal-dialog--header .modal-content {
      text-align: left; }
    .rtl-support .modal-dialog--header .modal-content {
      text-align: right; }
  .modal-dialog--header .modal__header {
    color: #ffffff;
    padding: 15px 20px;
    position: relative; }
  .modal-dialog--header .modal__title {
    margin: 0;
    font-weight: 300; }
  .modal-dialog--header .modal__close-btn {
    color: #ffffff;
    top: calc(50% - 8px); }
  .modal-dialog--header .modal__body {
    padding-top: 25px;
    padding-bottom: 20px; }
    .ltr-support .modal-dialog--header .modal__body {
      padding-right: 40px;
      padding-left: 20px; }
    .rtl-support .modal-dialog--header .modal__body {
      padding-left: 40px;
      padding-right: 20px; }
  .modal-dialog--header .modal__footer {
    margin-bottom: 40px;
    justify-content: flex-end; }
    .ltr-support .modal-dialog--header .modal__footer {
      padding-right: 20px; }
    .rtl-support .modal-dialog--header .modal__footer {
      padding-left: 20px; }
  .modal-dialog--header.modal-dialog--primary .modal__header {
    background-color: #70bbfd; }
  .modal-dialog--header.modal-dialog--success .modal__header {
    background-color: #4ce1b6; }
  .modal-dialog--header.modal-dialog--warning .modal__header {
    background-color: #f6da6e; }
  .modal-dialog--header.modal-dialog--danger .modal__header {
    background-color: #ff4861; }

.modal-dialog--carousel {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none; }
  .modal-dialog--carousel .modal-content {
    display: flex;
    align-items: center;
    box-shadow: none;
    background: none; }
    .ltr-support .modal-dialog--carousel .modal-content {
      text-align: left; }
    .rtl-support .modal-dialog--carousel .modal-content {
      text-align: right; }
  .modal-dialog--carousel .modal__header {
    color: #ffffff;
    height: 50px;
    text-align: right;
    padding: 10px 20px 10px 0;
    position: relative; }
  .modal-dialog--carousel .modal__title {
    margin: 0; }
  .modal-dialog--carousel .modal__close-btn {
    color: #ffffff; }
  .modal-dialog--carousel .modal__btn {
    padding: 0;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5); }
    .modal-dialog--carousel .modal__btn:hover:not([enabled]) {
      color: #ffffff; }
    .modal-dialog--carousel .modal__btn .modal__btn_left {
      width: 80px;
      height: 80px;
      position: absolute;
      left: 0; }
    .modal-dialog--carousel .modal__btn .modal__btn_right {
      width: 80px;
      height: 80px;
      position: absolute;
      right: 0; }
  .modal-dialog--carousel .modal__body {
    max-width: 50%;
    overflow: hidden; }
    .modal-dialog--carousel .modal__body li {
      align-items: stretch; }
    .ltr-support .modal-dialog--carousel .modal__body {
      padding-right: 40px;
      padding-left: 20px; }
    .rtl-support .modal-dialog--carousel .modal__body {
      padding-left: 40px;
      padding-right: 20px; }
  .modal-dialog--carousel .modal__footer {
    justify-content: flex-end;
    color: #ffffff; }

@media screen and (max-width: 768px) {
  .modal-dialog--carousel .modal__body {
    max-width: 100%; }
  .modal-dialog--carousel .modal__btn {
    display: none; } }

body {
  background-color: #f2f4f7; }
  body .ui.container.top-container {
    padding-top: 20px; }
  body .ui.container.full-width {
    padding: 2px; }

.page-content {
  padding: 0rem 1rem !important; }
  .page-content .hide {
    color: transparent; }
  .page-content .invisible {
    display: none; }
  .page-content .ui.card {
    margin: 0 0;
    margin-bottom: 2rem; }
  .page-content .right-content .ui.card {
    width: 100% !important; }

.custom-stretch {
  display: flex;
  flex-flow: column;
  height: 100%; }
  .custom-stretch .box-content {
    flex: 1 1 auto; }

.ui.grid > .row:not(.options) {
  padding: 0; }

.center-content {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center; }

.pos_fixed {
  position: realtive;
  margin: 0;
  text-align: center; }

.rounded {
  border-radius: 0.25rem !important; }

.banner {
  background-image: linear-gradient(90deg, #182a73 20%, #182a73 0%, #20a7ac 89%) !important; }

.user-profile-base {
  margin-top: 10%;
  display: flex; }
  .user-profile-base .ui.card {
    width: 60%;
    background-color: #fff;
    padding: 5rem 2rem;
    margin: auto;
    box-shadow: none; }
  .user-profile-base .text-center {
    text-align: center !important; }

.login {
  height: 100vh;
  display: flex;
  background-image: url("../images/ray-rui-Tvo1NEmfYB0-unsplash.jpg") !important;
  background-size: cover !important;
  background-position: center !important; }
  .login .ui.card {
    width: 30%;
    background-color: #fff;
    padding: 5rem 2rem;
    margin: auto;
    box-shadow: none; }
  .login .text-center {
    text-align: center !important; }

.overlay {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); }

.overlay-content {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: #555; }

.handsontable {
  z-index: 1 !important; }

/**
to take up the remaining height
<div id="outer">
 
The following are hacks for the Handsontable to hide portions of the column dropdownmenu items.
This has become necessary because explicit listing of the items in the dropdownmenu causes the filter action bar OK button to throw errors and therefore doesn't ::-webkit-scrollbar-track
The default version works, but comes with other menu items which are not needed. Hence this hack to hide those specific items
*/
td[aria-label*="Insert column left"] {
  display: none; }

td[aria-label*="Insert column right"] {
  display: none; }

td[aria-label*="Remove column"] {
  display: none; }

td[aria-label*="Clear column"] {
  display: none; }

td[aria-label*="Filter by condition:"] {
  display: none; }

img.banner {
  height: auto;
  max-width: 100%;
  display: block;
  background-size: cover; }

.fixed-button {
  position: fixed;
  bottom: 10px;
  right: 20px; }

.feedback-list {
  margin: 1em 1em 1em 1em; }

.custom-rating {
  position: relative;
  display: inline-block; }

.rating-numbers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  /* Ensure numbers don't interfere with clicking */ }

.rating-number {
  flex: 1;
  text-align: center;
  color: black;
  /* Adjust color as needed */
  font-size: 0.8em;
  /* Adjust size as needed */
  margin-top: 7px;
  /* Adjust positioning as needed */ }

.centered-card {
  height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 0 !important;
  /* Center the card horizontally on the page */
  text-align: center;
  background-image: url("../images/ray-rui-Tvo1NEmfYB0-unsplash.jpg") !important;
  background-size: cover !important;
  background-position: center !important; }
  .centered-card .small-card {
    top: 6em;
    margin-bottom: 0 !important; }

.outer-card {
  height: 100vh;
  background-image: url("../images/ray-rui-Tvo1NEmfYB0-unsplash.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: .3rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  transition: box-shadow .1s ease, transform .1s ease; }
  .outer-card .ui.card {
    top: 6rem;
    background-color: #fff;
    padding: 5rem 2rem;
    margin: auto;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    transition: box-shadow .1s ease, transform .1s ease; }
  .outer-card .text-center {
    text-align: center !important; }

/* On screens that are 992px or less */
@media screen and (max-width: 992px) {
  .login .ui.card {
    width: 50%; }
  .outer-card .ui.card {
    width: 50%; }
  .user-profile .ui.card {
    width: 50%; } }

/* On screens that are 600px or less */
@media screen and (max-width: 600px) {
  .login .ui.card {
    width: 70%; }
  .outer-card .ui.card {
    width: 70%; }
  .user-profile .ui.card {
    width: 70%; } }

/* On screens that are 320px or less*/
@media screen and (max-width: 320px) {
  .login .ui.card {
    width: 100%; }
  .outer-card .ui.card {
    width: 100%; }
  .user-profile .ui.card {
    width: 100%; }
  .centered-card .content {
    width: 100% !important; }
  .centered-card .small-card {
    top: 6em;
    margin-bottom: 0 !important;
    width: 100% !important; } }

.custom-ui-element .ui.grid > .row:not(.options) {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem; }

.custom-ui-element .ui.grid > .row > .column {
  line-height: 2; }

.ui.input.number {
  width: auto !important; }

.ui.card > .content,
.ui.cards > .card > .content {
  flex-grow: 0; }

.theme-light .account {
  background: #f2f4f7; }

.theme-dark .account {
  background: #2a2a31; }

.account .account__logo-accent {
  color: #70bbfd; }

.account .account__title {
  color: #ffffff; }

.account .account__head {
  margin-bottom: 30px;
  border-left: 4px solid #70bbfd;
  padding-left: 10px; }
  .ltr-support .account .account__head {
    padding-left: 10px;
    border-left: 4px solid #70bbfd; }
  .rtl-support .account .account__head {
    padding-right: 10px;
    border-right: 4px solid #70bbfd; }

.btn {
  border-radius: 5px;
  padding: 0.3rem 0.3rem !important;
  transition: all 0.4s;
  font-size: 0.5rem;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  z-index: 0;
  line-height: 1.5rem;
  cursor: pointer; }
  .ltr-support .btn {
    margin-right: 15px; }
  .rtl-support .btn {
    margin-left: 15px; }
  .btn:last-child {
    margin-right: 0; }
    .ltr-support .btn:last-child {
      margin-right: 0; }
    .rtl-support .btn:last-child {
      margin-left: 0; }
  .btn:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: #4ce1b6;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    z-index: -1;
    content: "";
    top: 0;
    left: 0; }
    .ltr-support .btn:before {
      transform: translate(-50%, -50%);; }
    .rtl-support .btn:before {
      transform: translate(50%, -50%);; }
    .ltr-support .btn:before {
      left: 0px; }
    .rtl-support .btn:before {
      right: 0px; }
  .btn p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500; }
  .btn svg {
    height: 14px;
    width: 14px;
    margin-top: 2px;
    margin-bottom: 0;
    transition: all 0.3s; }
    .ltr-support .btn svg {
      margin-right: 5px; }
    .rtl-support .btn svg {
      margin-left: 5px; }
    .ltr-support .btn svg {
      margin-left: 0px; }
    .rtl-support .btn svg {
      margin-right: 0px; }
  .btn:hover, .btn:focus, .btn:active, .btn:active:focus {
    outline: none;
    box-shadow: none !important; }
    .btn:hover:before, .btn:focus:before, .btn:active:before, .btn:active:focus:before {
      height: 500%;
      width: 225%; }
  .btn:focus:before, .btn:active:before, .btn:active:focus:before {
    transition: all 0s; }
  .btn.square {
    border-radius: 0; }
  .btn.rounded {
    border-radius: 30px !important; }
  .btn.icon {
    padding: 10px 15px; }
    .theme-light .btn.icon:hover p {
      color: #646777; }
    .theme-dark .btn.icon:hover p {
      color: #dddddd; }
    .theme-light .btn.icon:hover svg {
      fill: #646777; }
    .theme-dark .btn.icon:hover svg {
      fill: #dddddd; }
    .btn.icon.icon--right svg {
      margin-top: 2px;
      margin-bottom: 0; }
      .ltr-support .btn.icon.icon--right svg {
        margin-right: 0px;
        margin-left: 5px; }
      .rtl-support .btn.icon.icon--right svg {
        margin-left: 0px;
        margin-right: 5px; }
  .btn.btn-sm {
    padding: 5px 25px;
    font-size: 14px; }
  .btn.btn-lg {
    padding: 12px 25px;
    font-size: 14px; }
  .btn.btn-secondary {
    background-color: #d8dfe9;
    border-color: #d8dfe9;
    color: #787985; }
    .btn.btn-secondary:before {
      background-color: #b7c4d7; }
    .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active, .btn.btn-secondary:active:focus {
      border-color: #b7c4d7;
      color: #787985; }
    .btn.btn-secondary:not([disabled]):not(.disabled):active, .btn.btn-secondary:not([disabled]):not(.disabled).active {
      background-color: #dddddd;
      border-color: #dddddd; }
  .btn.btn-outline-secondary {
    border-color: #d8dfe9; }
    .theme-light .btn.btn-outline-secondary, .theme-light
    .btn.btn-outline-secondary p {
      color: #646777; }
    .theme-dark .btn.btn-outline-secondary, .theme-dark
    .btn.btn-outline-secondary p {
      color: #dddddd; }
    .theme-light .btn.btn-outline-secondary svg {
      fill: #646777; }
    .theme-dark .btn.btn-outline-secondary svg {
      fill: #dddddd; }
    .btn.btn-outline-secondary:before {
      background-color: #d8dfe9; }
    .btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary:active, .btn.btn-outline-secondary:active:focus {
      background: transparent;
      border-color: #d8dfe9;
      color: #787985; }
      .btn.btn-outline-secondary:hover p, .btn.btn-outline-secondary:focus p, .btn.btn-outline-secondary:active p, .btn.btn-outline-secondary:active:focus p {
        color: #787985; }
      .btn.btn-outline-secondary:hover svg, .btn.btn-outline-secondary:focus svg, .btn.btn-outline-secondary:active svg, .btn.btn-outline-secondary:active:focus svg {
        fill: #787985; }
  .btn.btn-primary {
    color: #ffffff;
    background-color: #70bbfd;
    border-color: #70bbfd; }
    .btn.btn-primary:before {
      background-color: #3ea3fc; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:active:focus {
      border-color: #3ea3fc; }
  .btn.btn-outline-primary {
    color: #70bbfd;
    border-color: #70bbfd; }
    .btn.btn-outline-primary p {
      color: #70bbfd; }
    .btn.btn-outline-primary svg {
      fill: #70bbfd; }
    .btn.btn-outline-primary:before {
      background-color: #70bbfd; }
  .btn.btn-success {
    color: #ffffff !important;
    background-color: #4ce1b6;
    border-color: #4ce1b6; }
    .btn.btn-success:before {
      background-color: #24d6a3; }
    .btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success:active, .btn.btn-success:active:focus {
      border-color: #24d6a3;
      background-color: #24d6a3; }
  .btn.btn-outline-success {
    color: #4ce1b6;
    border-color: #4ce1b6; }
    .btn.btn-outline-success p {
      color: #4ce1b6; }
    .btn.btn-outline-success svg {
      fill: #4ce1b6; }
    .btn.btn-outline-success:before {
      background-color: #4ce1b6; }
  .btn.btn-warning {
    background-color: #f6da6e;
    border-color: #f6da6e;
    color: #ffffff; }
    .btn.btn-warning:before {
      background-color: #f3ce3e; }
    .btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning:active, .btn.btn-warning:active:focus {
      border-color: #f3ce3e; }
  .btn.btn-outline-warning {
    color: #f6da6e;
    border-color: #f6da6e; }
    .btn.btn-outline-warning p {
      color: #f6da6e; }
    .btn.btn-outline-warning svg {
      fill: #f6da6e; }
    .btn.btn-outline-warning:before {
      background-color: #f6da6e; }
  .btn.btn-danger {
    background-color: #ff4861;
    border-color: #ff4861; }
    .btn.btn-danger:before {
      background-color: #ff1535; }
    .btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger:active, .btn.btn-danger:active:focus {
      border-color: #ff1535; }
  .btn.btn-outline-danger {
    color: #ff4861;
    border-color: #ff4861; }
    .btn.btn-outline-danger p {
      color: #ff4861; }
    .btn.btn-outline-danger svg {
      fill: #ff4861; }
    .btn.btn-outline-danger:before {
      background-color: #ff4861; }
  .btn.disabled, .btn.btn-primary.disabled, .btn.btn-danger.disabled, .btn.btn-warning.disabled, .btn.btn-success.disabled, .btn.btn-outline-secondary.disabled, .btn.btn-secondary.disabled, .btn.btn-outline-primary.disabled, .btn.btn-outline-danger.disabled, .btn.btn-outline-warning.disabled, .btn.btn-outline-success.disabled, .btn.icon.disabled, .btn.icon.btn-secondary.disabled {
    background-color: #f2f4f7;
    border-color: #f2f4f7;
    color: #dddddd;
    pointer-events: none; }
    .btn.disabled p, .btn.btn-primary.disabled p, .btn.btn-danger.disabled p, .btn.btn-warning.disabled p, .btn.btn-success.disabled p, .btn.btn-outline-secondary.disabled p, .btn.btn-secondary.disabled p, .btn.btn-outline-primary.disabled p, .btn.btn-outline-danger.disabled p, .btn.btn-outline-warning.disabled p, .btn.btn-outline-success.disabled p, .btn.icon.disabled p, .btn.icon.btn-secondary.disabled p {
      color: #dddddd; }
    .btn.disabled svg, .btn.btn-primary.disabled svg, .btn.btn-danger.disabled svg, .btn.btn-warning.disabled svg, .btn.btn-success.disabled svg, .btn.btn-outline-secondary.disabled svg, .btn.btn-secondary.disabled svg, .btn.btn-outline-primary.disabled svg, .btn.btn-outline-danger.disabled svg, .btn.btn-outline-warning.disabled svg, .btn.btn-outline-success.disabled svg, .btn.icon.disabled svg, .btn.icon.btn-secondary.disabled svg {
      fill: #dddddd; }
  .btn.btn-primary p, .btn.btn-danger p, .btn.btn-warning p, .btn.btn-success p {
    color: #ffffff; }
  .btn.btn-primary svg, .btn.btn-danger svg, .btn.btn-warning svg, .btn.btn-success svg {
    fill: #ffffff; }
  .btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus, .btn.btn-outline-primary:active, .btn.btn-outline-primary:active:focus, .btn.btn-outline-danger:hover, .btn.btn-outline-danger:focus, .btn.btn-outline-danger:active, .btn.btn-outline-danger:active:focus, .btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus, .btn.btn-outline-warning:active, .btn.btn-outline-warning:active:focus, .btn.btn-outline-success:hover, .btn.btn-outline-success:focus, .btn.btn-outline-success:active, .btn.btn-outline-success:active:focus {
    color: #ffffff;
    background: transparent; }
  .btn.btn-primary:hover p, .btn.btn-primary:focus p, .btn.btn-primary:active p, .btn.btn-primary:active:focus p, .btn.btn-danger:hover p, .btn.btn-danger:focus p, .btn.btn-danger:active p, .btn.btn-danger:active:focus p, .btn.btn-warning:hover p, .btn.btn-warning:focus p, .btn.btn-warning:active p, .btn.btn-warning:active:focus p, .btn.btn-success:hover p, .btn.btn-success:focus p, .btn.btn-success:active p, .btn.btn-success:active:focus p, .btn.btn-outline-primary:hover p, .btn.btn-outline-primary:focus p, .btn.btn-outline-primary:active p, .btn.btn-outline-primary:active:focus p, .btn.btn-outline-danger:hover p, .btn.btn-outline-danger:focus p, .btn.btn-outline-danger:active p, .btn.btn-outline-danger:active:focus p, .btn.btn-outline-warning:hover p, .btn.btn-outline-warning:focus p, .btn.btn-outline-warning:active p, .btn.btn-outline-warning:active:focus p, .btn.btn-outline-success:hover p, .btn.btn-outline-success:focus p, .btn.btn-outline-success:active p, .btn.btn-outline-success:active:focus p {
    color: #ffffff; }
  .btn.btn-primary:hover svg, .btn.btn-primary:focus svg, .btn.btn-primary:active svg, .btn.btn-primary:active:focus svg, .btn.btn-danger:hover svg, .btn.btn-danger:focus svg, .btn.btn-danger:active svg, .btn.btn-danger:active:focus svg, .btn.btn-warning:hover svg, .btn.btn-warning:focus svg, .btn.btn-warning:active svg, .btn.btn-warning:active:focus svg, .btn.btn-success:hover svg, .btn.btn-success:focus svg, .btn.btn-success:active svg, .btn.btn-success:active:focus svg, .btn.btn-outline-primary:hover svg, .btn.btn-outline-primary:focus svg, .btn.btn-outline-primary:active svg, .btn.btn-outline-primary:active:focus svg, .btn.btn-outline-danger:hover svg, .btn.btn-outline-danger:focus svg, .btn.btn-outline-danger:active svg, .btn.btn-outline-danger:active:focus svg, .btn.btn-outline-warning:hover svg, .btn.btn-outline-warning:focus svg, .btn.btn-outline-warning:active svg, .btn.btn-outline-warning:active:focus svg, .btn.btn-outline-success:hover svg, .btn.btn-outline-success:focus svg, .btn.btn-outline-success:active svg, .btn.btn-outline-success:active:focus svg {
    fill: #ffffff; }
  .btn.expand svg {
    width: 0;
    transition: all 0.3s; }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
  .btn.expand.expand--load svg {
    width: 14px;
    animation: rotating 2s linear infinite; }

.btn-feedback {
  border-radius: 5px;
  padding: 0.8em 1.5em 0.8em !important;
  transition: all 0.4s;
  font-size: 0.5rem;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  z-index: 0;
  line-height: 1.5rem;
  cursor: pointer; }
  .ltr-support .btn-feedback {
    margin-right: 15px; }
  .rtl-support .btn-feedback {
    margin-left: 15px; }
  .btn-feedback:last-child {
    margin-right: 0; }
    .ltr-support .btn-feedback:last-child {
      margin-right: 0; }
    .rtl-support .btn-feedback:last-child {
      margin-left: 0; }
  .btn-feedback:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: #4ce1b6;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    z-index: -1;
    content: "";
    top: 0;
    left: 0; }
    .ltr-support .btn-feedback:before {
      transform: translate(-50%, -50%);; }
    .rtl-support .btn-feedback:before {
      transform: translate(50%, -50%);; }
    .ltr-support .btn-feedback:before {
      left: 0px; }
    .rtl-support .btn-feedback:before {
      right: 0px; }
  .btn-feedback p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500; }
  .btn-feedback svg {
    height: 14px;
    width: 14px;
    margin-top: 2px;
    margin-bottom: 0;
    transition: all 0.3s; }
    .ltr-support .btn-feedback svg {
      margin-right: 5px; }
    .rtl-support .btn-feedback svg {
      margin-left: 5px; }
    .ltr-support .btn-feedback svg {
      margin-left: 0px; }
    .rtl-support .btn-feedback svg {
      margin-right: 0px; }
  .btn-feedback:hover, .btn-feedback:focus, .btn-feedback:active, .btn-feedback:active:focus {
    outline: none;
    box-shadow: none !important; }
    .btn-feedback:hover:before, .btn-feedback:focus:before, .btn-feedback:active:before, .btn-feedback:active:focus:before {
      height: 500%;
      width: 225%; }
  .btn-feedback:focus:before, .btn-feedback:active:before, .btn-feedback:active:focus:before {
    transition: all 0s; }
  .btn-feedback.square {
    border-radius: 0; }
  .btn-feedback.rounded {
    border-radius: 30px !important; }
  .btn-feedback.icon {
    padding: 10px 15px; }
    .theme-light .btn-feedback.icon:hover p {
      color: #646777; }
    .theme-dark .btn-feedback.icon:hover p {
      color: #dddddd; }
    .theme-light .btn-feedback.icon:hover svg {
      fill: #646777; }
    .theme-dark .btn-feedback.icon:hover svg {
      fill: #dddddd; }
    .btn-feedback.icon.icon--right svg {
      margin-top: 2px;
      margin-bottom: 0; }
      .ltr-support .btn-feedback.icon.icon--right svg {
        margin-right: 0px;
        margin-left: 5px; }
      .rtl-support .btn-feedback.icon.icon--right svg {
        margin-left: 0px;
        margin-right: 5px; }
  .btn-feedback.btn-sm {
    padding: 5px 25px;
    font-size: 14px; }
  .btn-feedback.btn-lg {
    padding: 12px 25px;
    font-size: 14px; }
  .btn-feedback.btn-secondary {
    background-color: #d8dfe9;
    border-color: #d8dfe9;
    color: #787985; }
    .btn-feedback.btn-secondary:before {
      background-color: #b7c4d7; }
    .btn-feedback.btn-secondary:hover, .btn-feedback.btn-secondary:focus, .btn-feedback.btn-secondary:active, .btn-feedback.btn-secondary:active:focus {
      border-color: #b7c4d7;
      color: #787985; }
    .btn-feedback.btn-secondary:not([disabled]):not(.disabled):active, .btn-feedback.btn-secondary:not([disabled]):not(.disabled).active {
      background-color: #dddddd;
      border-color: #dddddd; }
  .btn-feedback.btn-outline-secondary {
    border-color: #d8dfe9; }
    .theme-light .btn-feedback.btn-outline-secondary, .theme-light
    .btn-feedback.btn-outline-secondary p {
      color: #646777; }
    .theme-dark .btn-feedback.btn-outline-secondary, .theme-dark
    .btn-feedback.btn-outline-secondary p {
      color: #dddddd; }
    .theme-light .btn-feedback.btn-outline-secondary svg {
      fill: #646777; }
    .theme-dark .btn-feedback.btn-outline-secondary svg {
      fill: #dddddd; }
    .btn-feedback.btn-outline-secondary:before {
      background-color: #d8dfe9; }
    .btn-feedback.btn-outline-secondary:hover, .btn-feedback.btn-outline-secondary:focus, .btn-feedback.btn-outline-secondary:active, .btn-feedback.btn-outline-secondary:active:focus {
      background: transparent;
      border-color: #d8dfe9;
      color: #787985; }
      .btn-feedback.btn-outline-secondary:hover p, .btn-feedback.btn-outline-secondary:focus p, .btn-feedback.btn-outline-secondary:active p, .btn-feedback.btn-outline-secondary:active:focus p {
        color: #787985; }
      .btn-feedback.btn-outline-secondary:hover svg, .btn-feedback.btn-outline-secondary:focus svg, .btn-feedback.btn-outline-secondary:active svg, .btn-feedback.btn-outline-secondary:active:focus svg {
        fill: #787985; }
  .btn-feedback.btn-primary {
    color: #ffffff;
    background-color: #70bbfd;
    border-color: #70bbfd; }
    .btn-feedback.btn-primary:before {
      background-color: #3ea3fc; }
    .btn-feedback.btn-primary:hover, .btn-feedback.btn-primary:focus, .btn-feedback.btn-primary:active, .btn-feedback.btn-primary:active:focus {
      border-color: #3ea3fc; }
  .btn-feedback.btn-outline-primary {
    color: #70bbfd;
    border-color: #70bbfd; }
    .btn-feedback.btn-outline-primary p {
      color: #70bbfd; }
    .btn-feedback.btn-outline-primary svg {
      fill: #70bbfd; }
    .btn-feedback.btn-outline-primary:before {
      background-color: #70bbfd; }
  .btn-feedback.btn-success {
    color: #ffffff !important;
    background-color: #4ce1b6;
    border-color: #4ce1b6; }
    .btn-feedback.btn-success:before {
      background-color: #24d6a3; }
    .btn-feedback.btn-success:hover, .btn-feedback.btn-success:focus, .btn-feedback.btn-success:active, .btn-feedback.btn-success:active:focus {
      border-color: #24d6a3;
      background-color: #24d6a3; }
  .btn-feedback.btn-outline-success {
    color: #4ce1b6;
    border-color: #4ce1b6; }
    .btn-feedback.btn-outline-success p {
      color: #4ce1b6; }
    .btn-feedback.btn-outline-success svg {
      fill: #4ce1b6; }
    .btn-feedback.btn-outline-success:before {
      background-color: #4ce1b6; }
  .btn-feedback.btn-warning {
    background-color: #f6da6e;
    border-color: #f6da6e;
    color: #ffffff; }
    .btn-feedback.btn-warning:before {
      background-color: #f3ce3e; }
    .btn-feedback.btn-warning:hover, .btn-feedback.btn-warning:focus, .btn-feedback.btn-warning:active, .btn-feedback.btn-warning:active:focus {
      border-color: #f3ce3e; }
  .btn-feedback.btn-outline-warning {
    color: #f6da6e;
    border-color: #f6da6e; }
    .btn-feedback.btn-outline-warning p {
      color: #f6da6e; }
    .btn-feedback.btn-outline-warning svg {
      fill: #f6da6e; }
    .btn-feedback.btn-outline-warning:before {
      background-color: #f6da6e; }
  .btn-feedback.btn-danger {
    background-color: #ff4861;
    border-color: #ff4861; }
    .btn-feedback.btn-danger:before {
      background-color: #ff1535; }
    .btn-feedback.btn-danger:hover, .btn-feedback.btn-danger:focus, .btn-feedback.btn-danger:active, .btn-feedback.btn-danger:active:focus {
      border-color: #ff1535; }
  .btn-feedback.btn-outline-danger {
    color: #ff4861;
    border-color: #ff4861; }
    .btn-feedback.btn-outline-danger p {
      color: #ff4861; }
    .btn-feedback.btn-outline-danger svg {
      fill: #ff4861; }
    .btn-feedback.btn-outline-danger:before {
      background-color: #ff4861; }
  .btn-feedback.disabled, .btn-feedback.btn-primary.disabled, .btn-feedback.btn-danger.disabled, .btn-feedback.btn-warning.disabled, .btn-feedback.btn-success.disabled, .btn-feedback.btn-outline-secondary.disabled, .btn-feedback.btn-secondary.disabled, .btn-feedback.btn-outline-primary.disabled, .btn-feedback.btn-outline-danger.disabled, .btn-feedback.btn-outline-warning.disabled, .btn-feedback.btn-outline-success.disabled, .btn-feedback.icon.disabled, .btn-feedback.icon.btn-secondary.disabled {
    background-color: #f2f4f7;
    border-color: #f2f4f7;
    color: #dddddd;
    pointer-events: none; }
    .btn-feedback.disabled p, .btn-feedback.btn-primary.disabled p, .btn-feedback.btn-danger.disabled p, .btn-feedback.btn-warning.disabled p, .btn-feedback.btn-success.disabled p, .btn-feedback.btn-outline-secondary.disabled p, .btn-feedback.btn-secondary.disabled p, .btn-feedback.btn-outline-primary.disabled p, .btn-feedback.btn-outline-danger.disabled p, .btn-feedback.btn-outline-warning.disabled p, .btn-feedback.btn-outline-success.disabled p, .btn-feedback.icon.disabled p, .btn-feedback.icon.btn-secondary.disabled p {
      color: #dddddd; }
    .btn-feedback.disabled svg, .btn-feedback.btn-primary.disabled svg, .btn-feedback.btn-danger.disabled svg, .btn-feedback.btn-warning.disabled svg, .btn-feedback.btn-success.disabled svg, .btn-feedback.btn-outline-secondary.disabled svg, .btn-feedback.btn-secondary.disabled svg, .btn-feedback.btn-outline-primary.disabled svg, .btn-feedback.btn-outline-danger.disabled svg, .btn-feedback.btn-outline-warning.disabled svg, .btn-feedback.btn-outline-success.disabled svg, .btn-feedback.icon.disabled svg, .btn-feedback.icon.btn-secondary.disabled svg {
      fill: #dddddd; }
  .btn-feedback.btn-primary p, .btn-feedback.btn-danger p, .btn-feedback.btn-warning p, .btn-feedback.btn-success p {
    color: #ffffff; }
  .btn-feedback.btn-primary svg, .btn-feedback.btn-danger svg, .btn-feedback.btn-warning svg, .btn-feedback.btn-success svg {
    fill: #ffffff; }
  .btn-feedback.btn-outline-primary:hover, .btn-feedback.btn-outline-primary:focus, .btn-feedback.btn-outline-primary:active, .btn-feedback.btn-outline-primary:active:focus, .btn-feedback.btn-outline-danger:hover, .btn-feedback.btn-outline-danger:focus, .btn-feedback.btn-outline-danger:active, .btn-feedback.btn-outline-danger:active:focus, .btn-feedback.btn-outline-warning:hover, .btn-feedback.btn-outline-warning:focus, .btn-feedback.btn-outline-warning:active, .btn-feedback.btn-outline-warning:active:focus, .btn-feedback.btn-outline-success:hover, .btn-feedback.btn-outline-success:focus, .btn-feedback.btn-outline-success:active, .btn-feedback.btn-outline-success:active:focus {
    color: #ffffff;
    background: transparent; }
  .btn-feedback.btn-primary:hover p, .btn-feedback.btn-primary:focus p, .btn-feedback.btn-primary:active p, .btn-feedback.btn-primary:active:focus p, .btn-feedback.btn-danger:hover p, .btn-feedback.btn-danger:focus p, .btn-feedback.btn-danger:active p, .btn-feedback.btn-danger:active:focus p, .btn-feedback.btn-warning:hover p, .btn-feedback.btn-warning:focus p, .btn-feedback.btn-warning:active p, .btn-feedback.btn-warning:active:focus p, .btn-feedback.btn-success:hover p, .btn-feedback.btn-success:focus p, .btn-feedback.btn-success:active p, .btn-feedback.btn-success:active:focus p, .btn-feedback.btn-outline-primary:hover p, .btn-feedback.btn-outline-primary:focus p, .btn-feedback.btn-outline-primary:active p, .btn-feedback.btn-outline-primary:active:focus p, .btn-feedback.btn-outline-danger:hover p, .btn-feedback.btn-outline-danger:focus p, .btn-feedback.btn-outline-danger:active p, .btn-feedback.btn-outline-danger:active:focus p, .btn-feedback.btn-outline-warning:hover p, .btn-feedback.btn-outline-warning:focus p, .btn-feedback.btn-outline-warning:active p, .btn-feedback.btn-outline-warning:active:focus p, .btn-feedback.btn-outline-success:hover p, .btn-feedback.btn-outline-success:focus p, .btn-feedback.btn-outline-success:active p, .btn-feedback.btn-outline-success:active:focus p {
    color: #ffffff; }
  .btn-feedback.btn-primary:hover svg, .btn-feedback.btn-primary:focus svg, .btn-feedback.btn-primary:active svg, .btn-feedback.btn-primary:active:focus svg, .btn-feedback.btn-danger:hover svg, .btn-feedback.btn-danger:focus svg, .btn-feedback.btn-danger:active svg, .btn-feedback.btn-danger:active:focus svg, .btn-feedback.btn-warning:hover svg, .btn-feedback.btn-warning:focus svg, .btn-feedback.btn-warning:active svg, .btn-feedback.btn-warning:active:focus svg, .btn-feedback.btn-success:hover svg, .btn-feedback.btn-success:focus svg, .btn-feedback.btn-success:active svg, .btn-feedback.btn-success:active:focus svg, .btn-feedback.btn-outline-primary:hover svg, .btn-feedback.btn-outline-primary:focus svg, .btn-feedback.btn-outline-primary:active svg, .btn-feedback.btn-outline-primary:active:focus svg, .btn-feedback.btn-outline-danger:hover svg, .btn-feedback.btn-outline-danger:focus svg, .btn-feedback.btn-outline-danger:active svg, .btn-feedback.btn-outline-danger:active:focus svg, .btn-feedback.btn-outline-warning:hover svg, .btn-feedback.btn-outline-warning:focus svg, .btn-feedback.btn-outline-warning:active svg, .btn-feedback.btn-outline-warning:active:focus svg, .btn-feedback.btn-outline-success:hover svg, .btn-feedback.btn-outline-success:focus svg, .btn-feedback.btn-outline-success:active svg, .btn-feedback.btn-outline-success:active:focus svg {
    fill: #ffffff; }
  .btn-feedback.expand svg {
    width: 0;
    transition: all 0.3s; }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
  .btn-feedback.expand.expand--load svg {
    width: 14px;
    animation: rotating 2s linear infinite; }

.btn-group {
  border-radius: 5px;
  margin-bottom: -10px; }
  .btn-group .btn {
    padding: 10px 15px;
    font-weight: 500; }
    .ltr-support .btn-group .btn {
      margin-right: 0px; }
    .rtl-support .btn-group .btn {
      margin-left: 0px; }
  .btn-group.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .btn-group.btn-group--justified .btn {
      width: 100%; }
  .btn-group.btn-group--icons .btn {
    padding: 7px 8px;
    line-height: 14px; }
  .btn-group.open .dropdown-toggle {
    box-shadow: none; }

button:focus,
button:active {
  outline: none; }

.theme-light .open > .dropdown-toggle.btn-default, .theme-light
.btn-default:active:focus, .theme-light
.btn-default:active:focus, .theme-light
.btn-default.focus, .theme-light
.btn-default:focus {
  background-color: white; }

.theme-dark .open > .dropdown-toggle.btn-default, .theme-dark
.btn-default:active:focus, .theme-dark
.btn-default:active:focus, .theme-dark
.btn-default.focus, .theme-dark
.btn-default:focus {
  background-color: #232329; }

.customizer {
  position: fixed;
  top: 0;
  z-index: 102; }
  .ltr-support .customizer {
    right: 0px; }
  .rtl-support .customizer {
    left: 0px; }
  .ltr-support .customizer .customizer__toggle span {
    margin-right: 10px;
    margin-left: 0px; }
  .rtl-support .customizer .customizer__toggle span {
    margin-left: 10px;
    margin-right: 0px; }
  .theme-light .customizer .customizer__toggle span {
    color: #646777; }
  .theme-dark .customizer .customizer__toggle span {
    color: #dddddd; }

.box {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 20px solid currentcolor;
  border-width: 20px;
  border-radius: 3px;
  font-size: 0.5rem;
  padding: 1em;
  position: relative;
  line-height: 10px;
  margin-bottom: 0.25em;
  vertical-align: top;
  transition: 0.8s border, 0.8s transform, 0.8s opacity; }

.box {
  z-index: 90; }

.loader-01 {
  border: 0.2em dotted currentcolor;
  border-radius: 50%;
  animation: 1s loader-01 linear infinite; }

@keyframes loader-01 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(90deg, #182a73 20%, #182a73 0%, #20a7ac 89%) !important; }

.ui.menu {
  font-family: 'open-sans', sans-serif;
  font-size: 16px; }

.ui.menu :not(.pagination) .item {
  border: none !important; }

.ui.selection.dropdown .menu > .item {
  font-size: 0.9rem; }

.ui.multiple.dropdown > .label {
  font-size: 0.9rem; }

.ui.menu div.item {
  cursor: pointer; }

.ui.menu:not(.tabular) a.active.item,
.ui.menu .ui.active.item.dropdown {
  text-decoration: underline;
  text-decoration-thickness: 0.2rem;
  text-underline-offset: -1.8em; }

.ui.menu:not(.tabular) a.item:not(.icon, .exclude):hover,
.ui.menu div.divider.text:hover,
div.ui.item.dropdown > div.item:not(a):hover,
div.ui.menu div.menu div.item.dropdown:not(i):hover,
.ui.menu .item > i.dropdown:not(.icon):hover {
  text-decoration: underline;
  text-decoration-thickness: 0.2rem;
  text-decoration-color: #b3be62;
  text-underline-offset: -1.8em; }

a.esvd-hyperlink {
  text-decoration-line: underline;
  text-decoration-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87); }

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2; }

div.data-table {
  width: 800px;
  height: 500px;
  overflow-x: scroll; }
  div.data-table th,
  div.data-table td {
    white-space: nowrap; }

td[role="gridcell"] div {
  overflow: hidden;
  white-space: nowrap;
  /* display: inline; */ }

div.outer {
  display: flex;
  height: 75vh;
  flex-direction: row;
  align-items: stretch; }

#inner1 {
  flex: 1;
  overflow-x: scroll; }

#inner2 {
  display: flex;
  flex-flow: column;
  height: 100%; }

#inner2top {
  flex: 0 1 auto;
  background-color: cyan;
  float: left;
  position: fixed;
  width: 100%; }

#inner2bottom {
  flex: 1 1 auto; }

.AdvanceTable:not(.AdvanceTable--show-left-shadow) .AdvanceTable__table-frozen-left {
  box-shadow: none; }

.AdvanceTable:not(.AdvanceTable--show-right-shadow) .AdvanceTable__table-frozen-right {
  box-shadow: none; }

.BaseContainer {
  margin: 0 0.5rem !important;
  height: 90%; }

.ui.dropdown .menu > .item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  text-transform: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none; }

.ui.dropdown .menu > .item:first-child {
  border-top-width: 0px; }

.dropzone {
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  border: 1px solid #f2f4f7;
  background-color: #d8dfe9; }
  .theme-light .dropzone {
    border: 1px solid #f2f4f7; }
  .theme-dark .dropzone {
    border: 1px solid #33333a; }
  .dropzone.dropzone--single {
    height: 3rem; }
    .dropzone.dropzone--single .dropzone__img {
      margin: 0;
      height: 100%;
      width: 100%;
      text-align: center; }
      .dropzone.dropzone--single .dropzone__img img {
        padding: 20px;
        height: 100%;
        width: auto; }
    .dropzone.dropzone--single .dropzone__input:hover {
      background-size: 30px 30px;
      animation: striped 2s linear infinite; }
      .theme-light .dropzone.dropzone--single .dropzone__input:hover {
        background-image: linear-gradient(-45deg, #eff1f5 25%, transparent 25%, transparent 50%, #eff1f5 50%, #eff1f5 75%, transparent 75%, transparent); }
      .theme-dark .dropzone.dropzone--single .dropzone__input:hover {
        background-image: linear-gradient(-45deg, #333246 25%, transparent 25%, transparent 50%, #333246 50%, #333246 75%, transparent 75%, transparent); }

@keyframes striped {
  from {
    background-position: 0 0; }
  to {
    background-position: 60px 30px; } }
  .dropzone.dropzone--custom-height {
    height: auto; }
    .dropzone.dropzone--custom-height .dropzone__img {
      max-width: 100%; }
      .dropzone.dropzone--custom-height .dropzone__img img {
        max-width: 100%;
        height: auto; }
  .dropzone.dropzone--multiple {
    min-height: 400px; }
    .dropzone.dropzone--multiple .dropzone__input {
      min-height: 400px; }

.dropzone__input {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute !important;
  cursor: pointer;
  justify-content: center; }

.dropzone__drop-here {
  margin: auto;
  color: #999999; }

.dropzone__imgs-wrapper {
  padding: 30px 20px;
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap; }

.dropzone__img {
  margin-bottom: 30px;
  width: calc(16.6667% - 30px);
  height: 150px;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .theme-light .dropzone__img {
    outline: 1px solid #eff1f5; }
  .theme-dark .dropzone__img {
    outline: 1px solid #333246; }
  .dropzone__img:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    cursor: default;
    left: 0;
    top: 0;
    bottom: 0; }
    .ltr-support .dropzone__img:before {
      right: 0px; }
    .rtl-support .dropzone__img:before {
      left: 0px; }
  .dropzone__img:hover:before {
    background-color: rgba(25, 25, 25, 0.6); }
  .dropzone__img:hover .dropzone__img-delete {
    opacity: 1; }
  .dropzone__img:hover .dropzone__img-name {
    opacity: 0.7; }

.dropzone__img-name {
  color: white;
  position: absolute;
  font-size: 12px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  z-index: 10;
  width: 100%;
  line-height: 12px;
  margin: 0;
  top: calc(50% - 6px); }

.dropzone__img-delete {
  transition: all 0.3s;
  position: absolute;
  top: 10px;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  opacity: 0;
  color: white;
  font-size: 9px;
  border: 1px solid white;
  text-transform: uppercase;
  padding: 2px 7px;
  line-height: 10px; }
  .ltr-support .dropzone__img-delete {
    right: 15px; }
  .rtl-support .dropzone__img-delete {
    left: 15px; }

/* hack css for google map elements */
div.gmnoprint > div:nth-of-type(1),
button.gm-fullscreen-control {
  border-radius: 12px !important; }

button.gm-fullscreen-control {
  display: none; }

div.gm-style-mtc {
  border-radius: 12px !important; }

/* Four values - border-radius: 15px 50px 30px 5px; (first value applies to top-left corner, second value applies to top-right corner, third value applies to bottom-right corner, and fourth value applies to bottom-left corner): */
div.gmnoprint > div.gm-style-mtc:first-child > button {
  border-radius: 12px 0px 0px 12px !important; }

div.gmnoprint > div.gm-style-mtc:last-child > button {
  border-radius: 0px 12px 12px 0px !important; }
